/*
Define the styled-components theme.

Referenced in css templates as ${props => props.theme.*}
*/

const coloursV2 = {
  secondary50: "#F5FAF8",
  secondary500: "#497E7A",
  secondary400: "#629D94",
  secondary900: "#26383B",
  secondary800: "#2B4346",
  secondary700: "#315254",
  secondary600: "#3A6968",
  secondary100: "#DAECE4",
  secondary200: "#B7D7CD",
  secondary300: "#8bbbaf",
  scrollBar: "#B5B5B5",
  darkGreen: "#272D37",
  gray900: "#101828",
  gray200: "#EAECF0",
  gray700: "#344054",
  gray50: "#F9FAFB",
  gray500: "#667085",
  gray100: "#F2F4F7",
  whiteShade: "#fff",
  blackShade: "#1F1F23",
  greenShade: "#2B4346",
  silverShade: "#667085",
  grayShade: "#757575",
  link: "#3A6968",
  black: "#000",
  primary400: "#FEC51B",
  beigeShade: "#FBF8F5",
  green: "#41C689",
}

/* Colour palette */
const colours = {
  primary: "#FEC51B",
  lightPrimary: "#FEDB4D", // New scheme - Natasha
  darkPrimary: "#CB9C0E", // New scheme - Natasha
  secondary: "#182325", // New scheme - Natasha
  lightSecondary: "#315254", // New scheme - Natasha
  paleSecondary: "#629D94", // New scheme - Natasha
  backgroundPaleYellow: "#FFF5C7  ", // New scheme - Natasha
  green2: "#41C689", // New scheme - Natasha
  red2: "#FF6133", // New scheme - Natasha
  accentPurple: "#6D51F6",
  accentRed: "#F21515",
  accentRed2: "#FF4444",
  orange: "#FD7F20",
  green: "#398C07",
  lightBlue: "#EAF3F5",
  darkBlue: "#022C4B",
  darkerGray: "#333333",
  darkGray: "#717171",
  lightGray: "#999999",
  reviewDateGray: "#C2C2C2",
  mapIconGray: "#CDCFDB",
  borderGray: "#E5E5E5",
  lighterGray: "#EAE9F2",
  buttonGray: "#F2F2F2",
  backgroundGray: "#F7F7F7",
  paleGray: "#FAFAFA",
  progressBarGray: "#F0F2F2",
  progressBarShadow: "#e3e6e6",
  black: "#111111",
  white: "#FFFFFF",
  purple: "#6E48BE",
  pastelBlue: "#379FDC",
  pastelGreen: "#21C17C",
  red: "#FF0000",
  paleYellow: "#FEF9E8",
}
const shadows = {
  darkPrimary:
    "0px 1px 2px rgba(16, 24, 40, 0.05),0px 0px 0px 4px rgba(203, 156, 14, 0.57);",
  paleSecondary:
    "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(98, 157, 148, 0.33);",
}
/* Colour elements */
const elements = {
  bg1: colours.white,
  bg2: colours.paleGray,
  fg1: colours.black,
  fg2: colours.white,
  border1: colours.lighterGray,
  placeholder: colours.lightGray,
  focusOutline: "2px solid #000000;",
  highlight: "#FEC51B1C;",
  scrollbar: {
    base: {
      trackOutline: colours.borderGray,
      thumb: colours.borderGray,
    },
    blue: {
      track: "#A4B7C1",
      thumb: colours.darkBlue,
    },
  },
  status: {
    error: "red",
    success: "green",
    info: "blue",
  },
  difficulty: {
    circle: "green",
    circleNew: "#41C689",
    square: "blue",
    diamond: "black",
    doubleDiamond: "red",
  },
}

/* Layers (z-index) */
const layers = {
  modals: 900,
  overlay2: 800,
  siteHeader: 700,
  panels: 600,
  overlay1: 500,
  activeUI: 400,
  floatingUI: 300,
}

/* Responsive breakpoints */
/*
 * be careful if changing any
 *  - header breakpoints depend on these
 */
export const screen = {
  /* 320px — 480px: Mobile devices. */
  mobile: {
    max: "max-width: 480px",
    maxWidth: 480,
  },

  /* 481px - 768px: iPads, Tablets. */
  tablet: {
    min: "min-width: 481px",
    max: "max-width: 768px",
    minWidth: 481,
    maxWidth: 768,
  },

  /* 769px - 1024px: iPad Pro, small screens. */
  small: {
    min: "min-width: 769px",
    max: "max-width: 1024px",
    minWidth: 769,
    maxWidth: 1024,
  },

  /* 1025px - 1260px: Laptop, medium screens. */
  medium: {
    min: "min-width: 1025px",
    max: "max-width: 1260px",
    minWidth: 1025,
    maxWidth: 1260,
    contentMaxWidths: 960,
    contentColumns: {
      sideWidth: 285,
      spacing: 40,
    },
  },

  /* 1261px and more - Large screens. */
  large: {
    min: "min-width: 1261px",
    max: "max-width: 1440px",
    minWidth: 1261,
    maxWidth: 1440,
    contentMaxWidths: 1140,
    contentColumns: {
      sideWidth: 285,
      spacing: 40,
    },
  },

  /* 1261px and more - Desktops. */
  desktop: {
    min: "min-width: 1441px",
    minWidth: 1441,
    contentMaxWidths: 1280,
    contentColumns: {
      sideWidth: 285,
      spacing: 40,
    },
  },
}

/* Special breakpoints for the Header */
export const header = {
  tablet: {
    max: "max-width: 808px",
    maxWidth: 808,
    height: 60,
  },
  small: {
    min: "min-width: 809px",
    max: screen.small.max,
    minWidth: 809,
    maxWidth: screen.small.maxWidth,
    height: 120,
  },
  medium: {
    min: screen.medium.min,
    max: screen.medium.max,
    minWidth: screen.medium.minWidth,
    maxWidth: screen.medium.maxWidth,
    height: 120,
  },
  large: {
    min: screen.large.min,
    max: "max-width: 1464px",
    minWidth: screen.large.minWidth,
    maxWidth: 1464,
    height: 80,
  },
  full: {
    min: "min-width: 1465px",
    minWidth: 1465,
  },
}

/* Special breakpoints for the Footer */
export const footer = {
  tablet: {
    max: screen.tablet.max,
    maxWidth: screen.tablet.maxWidth,
  },
  small: {
    ...screen.small,
  },
  medium: {
    ...screen.medium,
  },
  large: {
    ...screen.large,
  },
  full: {
    ...screen.desktop,
  },
}

const theme = {
  ...colours,
  ...coloursV2,
  ...elements,
  shadows,
  layers,
  screen,
  header,
  footer,
}
export default theme

export const heights = {
  navBar: 80,
}
