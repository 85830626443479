export { PUBLISH_CURRENCY as DEFAULT_LOCALE_CURRENCY } from "./currency"
export const DEFAULT_LOCALE_UNITS = process.env.GATSBY_DEFAULT_UNITS || null

export const PHONE_NUMBER = {
  CA: "1-855-909-3105",
  US: "1-855-909-3105",
  UK: "0808 196 0950",
  GB: "0808 196 0950",
  EU: "+44 208 106 2028",
}

export const FLAG = {
  CA: "🇨🇦",
  US: "🇺🇸",
  UK: "🇬🇧",
  GB: "🇬🇧",
  EU: "🇪🇺",
}

export const EU_COUNTRIES = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
]
