import React, { useState, useEffect, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import debounce from "debounce"
import Auth from "@src/services/user/auth"
import GTM from "@src/services/gtm"
import { invertObject } from "@src/utils/objects"

const fieldMapCognito = {
  profile: {
    email: "email",
    displayName: "preferred_username",
    profilePhoto: "picture",
    locale: "locale",
    countryCode: "custom:country_code",
  },
  preferences: {
    units: "custom:unit_of_measure",
    currency: "custom:currency_code",
    newsletter: "custom:newsletter_sub",
  },
}
const cognitoFieldMap = {
  profile: invertObject(fieldMapCognito.profile),
  preferences: invertObject(fieldMapCognito.preferences),
}

export const UserContext = React.createContext({
  user: undefined,
  updateUserPreferences: () => {},
  updateUserProfile: () => {},
  signin: () => {},
  signout: () => {},
  signup: () => {},
  recover: () => {},
  verify: () => {},
  verifyResend: () => {},
})

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [userFlowMode, setUserFlowMode] = useState(null)
  const [showUserModal, setShowUserModal] = useState(false)

  const refreshUser = result => {
    Auth.getUser()
      .then(userDataFields => {
        const userData = {
          profile: {},
          preferences: {},
        }
        if (userDataFields && userDataFields.length) {
          for (const field of userDataFields) {
            if (cognitoFieldMap.profile[field.Name]) {
              userData.profile[cognitoFieldMap.profile[field.Name]] =
                field.Value
            }
            if (cognitoFieldMap.preferences[field.Name]) {
              userData.preferences[cognitoFieldMap.preferences[field.Name]] =
                field.Value
            }
          }
        }
        const hasAccount = userData.profile.email ? true : false

        setUser(hasAccount ? userData : null)

        if (hasAccount) {
          GTM.dataLayerPush({
            event: "10a.user.account.loaded",
          })
        }
      })
      .catch(err => {
        console.log(err)
      })

    // let result arg flow through
    return result
  }

  const updateUser = useCallback(
    (changes, fieldMap) => {
      if (!user) {
        return
      }
      const updates = {}
      for (const key in changes) {
        if (fieldMap[key]) {
          updates[fieldMap[key]] = changes[key]
        }
      }
      return Auth.updateAttributes(updates).then(refreshUser)
    },
    [user],
  )
  const updateUserPreferences = useMemo(
    () =>
      debounce(changes => {
        updateUser(changes, fieldMapCognito.preferences)
      }, 1000),
    [updateUser],
  )
  const updateUserProfile = useMemo(
    () =>
      debounce(changes => {
        updateUser(changes, fieldMapCognito.profile)
      }, 1000),
    [updateUser],
  )

  // load-in effect
  useEffect(refreshUser, [])

  // storage change instant refresh user
  useEffect(() => {
    const window = global.window
    if (typeof window === "undefined") return
    window?.addEventListener("storage", refreshUser)

    return function cleanup() {
      window?.removeEventListener("storage", refreshUser)
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        user,
        updateUserPreferences,
        updateUserProfile,
        userFlowMode,
        showUserModal,
        setUserFlowMode: setUserFlowMode,
        setShowUserModal: setShowUserModal,
        signin: (username, password) =>
          Auth.signin(username, password).then(refreshUser),
        signout: () => Auth.signout().then(refreshUser),
        signup: (username, password) =>
          Auth.signup(username, password).then(refreshUser),
        recover: username => Auth.recoverAccount(username),
        recoverPassword: (username, code, password) =>
          Auth.recoverPassword(username, code, password),
        verify: (username, code) =>
          Auth.verifyAccount(username, code).then(refreshUser),
        verifyResend: username => Auth.verificationResend(username),
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
UserProvider.propTypes = {
  children: PropTypes.node,
}
